
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator"
import moment from "moment"
import BasicInfoField from "@/components/consultant/BasicInfoField.vue"
import EditProfile from "@/views/consultant/EditProfile.vue"
import { ConsultantProfile } from "@/gql"
import AvailabilityBadge from "@/components/consultant/AvailabilityBadge.vue"
import { EmploymentStatuses, JobFunctions } from "@/constants"

@Component({
  components: {
    AvailabilityBadge,
    BasicInfoField,
    EditProfile,
  },
})
export default class BasicInfo extends Vue {
  @Prop({ required: true }) readonly consultant!: ConsultantProfile
  @Prop({ default: false }) readonly editable!: boolean
  @Prop() readonly hideDetails?: boolean
  @Prop() readonly editMode?: boolean

  showEditModal = false

  getEmploymentStatus(value: string) {
    return EmploymentStatuses.find((status) => status.value === value)?.text
  }

  getJobFunctions(values: string[]) {
    return (
      JobFunctions.filter((status) => values.includes(status.value))
        .map((func) => func.text)
        ?.join(" , ") || ""
    )
  }

  get tenure() {
    if (this.consultant) {
      // Get duration uptil now
      let created: number = moment().diff(moment(this.consultant.createdAt), "minutes")
      let unit = ""

      if (created > 0) {
        unit = "minutes"

        if (created > 59) {
          unit = "hours"
          // An hour or more
          created = Math.round(created / 60)
          // A day or more
          if (created > 23) {
            unit = "days"
            created = Math.round(created / 24)

            // A month or more
            if (created > 27) {
              unit = "Months"
              created = Math.round(created / 28)

              // A year or more
              if (created > 12) {
                unit = "years"
                created = Math.round(created / 12)
              }
            }
          }
        }
      }
      return `${created}  ${unit}`
    }
    return undefined
  }

  get createdAt() {
    if (this.consultant) {
      return moment(this.consultant.createdAt).format("Do MMM YYYY")
    }
    return undefined
  }

  @Watch("editMode")
  onEditModeChange() {
    if (this.editMode) {
      this.showEditModal = true
    }
  }

  @Emit("save")
  onSave() {
    this.showEditModal = false
    return true
  }

  onEditModalClose() {
    this.$emit("update:editMode", false)
  }
}
